import React, {useEffect, useState} from 'react'
import '../../components/TableList/tableList.scss'
import {Pagination} from "../../components/Pagination/Pagination"
import {ApiErrorStatusEnum, objectIsEmpty, stringIsEmpty} from "edah_utils/dist"
import {queryCvisNotifyrecNoReply, queryCvisNotifyUnit} from "../../api/v1/Critical"
import CvisListItem from "../../components/CVIS/CvisListItem"
import {fuseObject} from "../../components/CVIS/utils"
import {t} from "i18next"
import useToast from "../../hooks/useToast";
import {SearchTextField} from "../../components/SearchTextField/SearchTextField"
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Checkbox,
    DateRangePicker,
    Select
} from "edah-component/dist";
import dayjs from "dayjs";

/**
 * 危急值逾時回覆查詢
 * @return {JSX.Element}
 */
const CrisisAlertTimeoutReplyQuery = () => {
    const showToast = useToast()
    // 通報單位選單
    const [unit, setUnit] = useState([])
    // 查詢條件
    const [cvisQueryData, setCvisQueryData] = useState({
        cvisNotifyUnit: '',
        cvisClassA: 'Y',
        cvisClassB: 'N',
        cvisClassC: 'N',
        cvisNotifyStartDate: '',
        cvisNotifyEndDate: '',
    })
    // 危急值回覆清單
    const [cvisNotifyRecordList, setCvisNotifyRecordList] = useState([])
    // 過濾危急值回覆清單
    const [filteredItems, setFilteredItems] = useState([])
    // 進階搜尋字串
    const [queryString, setQueryString] = useState('')
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,   //default
        pageSize: 10, //default
        totalItemSize: 0,
        totalPageSize: 0,
    })

    /**
     * 控制通報單位變動事件
     * @param value {String}
     * @return {void}
     */
    const handleCvisNotifyUnitOnChange = (value) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyUnit: value
        })
    }

    /**
     * 通報日期起訖變動事件
     * @param newDates {Array<Dayjs | null>} 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleCvisNotifyDateOnChange = (newDates) => {
        const [startDate, endDate] = newDates;

        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyStartDate: startDate ? startDate.format('YYYY-MM-DD') : null,
            cvisNotifyEndDate: endDate ? endDate.format('YYYY-MM-DD') : null,
        });
    };

    /**
     * 控制Checkbox變動事件
     * @param event {Object}
     * @return {void}
     */
    const handleCheckboxOnChange = (event) => {
        const {id, checked} = event.target
        setCvisQueryData({
            ...cvisQueryData,
            [id]: checked ? 'Y' : 'N'
        })
    }

    /**
     * 進階搜尋按鈕事件
     * @return {void}
     */
    const handleTableQuerySearchClick = () => {
        console.log('進階搜尋')
    }

    /**
     * 搜尋Table的Input box觸發Key Dowon事件
     * @param event {Object}
     * @return {void}
     */
    const handleTableQuerySearchInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleTableQuerySearchClick();
        }
    }

    /**
     * 控制Query輸入框內的值變動事件
     * @param event {Object}
     * @return {void}
     */
    const handleQueryInputOnChange = (event) => {
        if (event.target.value === ' ')
            setQueryString('');
        else
            setQueryString(event.target.value.toUpperCase())
    }

    /**
     * 危急值逾時回覆查詢表格指定頁碼變動事件
     * @param page {number}
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisNotifyList(page, paginationProps.pageSize);
    }

    /**
     * 危急值逾時回覆查詢表格指定每頁筆數變動事件
     * @param e {Object}
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        queryCvisNotifyList(paginationProps.pageNum, newSize);
    }

    /**
     * 危急值逾時回覆查詢表格上一頁按鈕事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisNotifyList(page, paginationProps.pageSize);
    }

    /**
     * 危急值逾時回覆查詢表格下一頁按鈕事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        queryCvisNotifyList(page, paginationProps.pageSize);
    }

    /**
     * 取得危急值逾時回覆查詢結果
     * @param page {Number} 指定頁碼
     * @param pageSize {Number} 指定每頁筆數
     * @return {void}
     */
    const queryCvisNotifyList = (page, pageSize) => {
        queryCvisNotifyrecNoReply({
            "pageNum": page,
            "pageSize": pageSize,
            "cvisNotifyUnit": cvisQueryData.cvisNotifyUnit,
            "cvisClassA": cvisQueryData.cvisClassA,
            "cvisClassB": cvisQueryData.cvisClassB,
            "cvisClassC": cvisQueryData.cvisClassC,
            "cvisNotifyStartDate": `${cvisQueryData.cvisNotifyStartDate} 00:00:00`,
            "cvisNotifyEndDate": `${cvisQueryData.cvisNotifyEndDate} 00:00:00`,
        }).then(res => {
            // 錯誤狀態 / 資料 / 訊息
            const {err, data, msg} = res

            if (err === ApiErrorStatusEnum.Success) {
                // 資料 / 總筆數 / 總頁數
                const {dataList, totalItemSize, totalPageSize} = data
                // 設定危急值回覆清單
                setCvisNotifyRecordList(dataList)
                // 設定過濾後清單
                setFilteredItems(dataList)
                // 設定分頁
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: totalItemSize,
                    totalPageSize: totalPageSize,
                    pageNum: page,
                    pageSize: pageSize
                })
            } else { // 錯誤處理
                // 重置逾時回覆清單
                setCvisNotifyRecordList([])
                // 重置過濾後清單
                setFilteredItems([])
                // 重置分頁
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageNum: 1,
                    pageSize: 10
                })
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 查詢按鈕事件
     * @return {void}
     */
    const handleQuerySubmit = () => {
        if (cvisQueryData.cvisNotifyEndDate && cvisQueryData.cvisNotifyStartDate > cvisQueryData.cvisNotifyEndDate) {
            showToast({message: '結束日期不可早於起始日期', type: AlertTypeEnum.Error})
        } else if (stringIsEmpty(cvisQueryData.cvisNotifyEndDate) || stringIsEmpty(cvisQueryData.cvisNotifyStartDate)) {
            showToast({message: '請輸入起始日期及結束日期', type: AlertTypeEnum.Error})
        } else {
            queryCvisNotifyList(paginationProps.pageNum, paginationProps.pageSize)
        }
    }

    /**
     * 當搜尋文字改變時，取得過濾後結果
     * @return {void}
     */
    useEffect(() => {
        if (queryString) {
            const result = fuseObject(cvisNotifyRecordList).search(queryString)
            setFilteredItems(result.map(item => item.item))
        } else {
            setFilteredItems(cvisNotifyRecordList)
        }
    }, [queryString, cvisNotifyRecordList])

    /**
     * 初始化後取得通報單位
     * @return {void}
     */
    useEffect(() => {
        queryCvisNotifyUnit({}).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setUnit(res.data);
                if (res.data.length > 0) {
                    setCvisQueryData(prev => ({
                        ...prev,
                        cvisNotifyUnit: res.data[0].cvisNotifyUnitNo
                    }));
                }
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }, [])

    return (
        <div className='w-full p-4'>
            {/* 主要內容 */}
            <div className='w-full flex flex-col gap-4'>
                {/* 醫生及回覆資訊 */}
                <div className='w-full flex flex-row justify-start items-center'>
                    <div className='w-[210px] h-10 flex flex-row justify-start items-center text-left'>
                        <Select
                            data={{
                                label: '通報單位',
                                options: unit.map((item) => ({
                                    label: item.cvisNotifyUnitName,
                                    value: item.cvisNotifyUnitNo
                                }))
                            }}
                            value={cvisQueryData.cvisNotifyUnit} notched={true}
                            onChange={handleCvisNotifyUnitOnChange}/>
                    </div>
                    <div className="min-w-[467px] flex flex-row items-center gap-1">
                        {/* 通報日期起訖 */}
                        <DateRangePicker
                            size="small"
                            localeText={{
                                start: '通報開始日期',
                                end: '通報結束日期'
                            }}
                            value={[
                                cvisQueryData.cvisNotifyStartDate ? dayjs(cvisQueryData.cvisNotifyStartDate) : null,
                                cvisQueryData.cvisNotifyEndDate ? dayjs(cvisQueryData.cvisNotifyEndDate) : null
                            ]}
                            onChange={handleCvisNotifyDateOnChange}/>
                    </div>
                    <div className="flex flex-row ml-4 space-x-4 items-center">
                        {/* A級 */}
                        <Checkbox
                            id="cvisClassA"
                            label="A級"
                            checked={cvisQueryData.cvisClassA === 'Y'}
                            onChange={handleCheckboxOnChange}/>
                        {/* B級 */}
                        <Checkbox
                            id="cvisClassB"
                            label="B級"
                            checked={cvisQueryData.cvisClassB === 'Y'}
                            onChange={handleCheckboxOnChange}/>
                        {/* C級 */}
                        <Checkbox
                            id="cvisClassC"
                            label="C級"
                            checked={cvisQueryData.cvisClassC === 'Y'}
                            onChange={handleCheckboxOnChange}/>
                    </div>
                    {/* 查詢按鈕 */}
                    <Button sx={{marginLeft: '1rem'}} color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Contained} size={ButtonSizeEnum.Medium}
                            text={t('general.query')} onClick={handleQuerySubmit}/>
                </div>
                {/* 危急值逾時回覆查詢結果 */}
                <Card sx={{pt: 2}}>
                    {/* 搜尋欄 */}
                    <div className='flex justify-start mb-4'>
                        <SearchTextField
                            value={queryString}
                            onChange={(event) => handleQueryInputOnChange(event)}
                            onKeyDown={handleTableQuerySearchInputKeyDown}
                            placeholder={t('general.advancedSearch')}/>
                    </div>
                    {/* 清單表格 */}
                    <div className='min-w-[1632px]'>
                        <ul className='tableList crisisAlertTimeoutQuerySearch max-h-[736px]'>
                            <li className='title'>
                                <div>等級</div>
                                <div>發送日期時間</div>
                                <div>回覆時效</div>
                                <div>回覆期限</div>
                                <div>病歷號</div>
                                <div>{t('general.username')}</div>
                                <div>主治醫師</div>
                                <div>醫師姓名</div>
                                <div>通報內容</div>
                            </li>
                            {
                                !objectIsEmpty(cvisNotifyRecordList) && filteredItems.map((item, index) =>
                                    <CvisListItem index={index} type="TimeoutReplyQuery" item={item}/>
                                )
                            }
                        </ul>
                    </div>
                    {/* 分頁 */}
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        currentPage={paginationProps.pageNum}
                        onPageOnChange={onPaginationPageOnChange}
                        onPageSizeChange={onPaginationPageSizeOnChange}
                        onPrevPageOnClick={onPaginationPreviousOnClick}
                        onNextPageOnClick={onPaginationNextOnClick}/>
                </Card>
            </div>
        </div>
    )
}

export default CrisisAlertTimeoutReplyQuery;
