import React, {useEffect, useState, useMemo} from 'react'
import '../../components/TableList/tableList.scss'
import {ApiErrorStatusEnum, arrayIsEmpty, fuzzySearchObjects, objectIsEmpty} from "edah_utils/dist"
import {Pagination} from "../../components/Pagination/Pagination"
import CvisListItem from '../../components/CVIS/CvisListItem'
import {getCurrentFormattedDateTime} from "../../components/CVIS/utils"
import {queryCvisClassANoReply, queryCvisNotifyUnit, queryCvisRenotify, saveCvisRenotify} from "../../api/v1/Critical"
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    IconEnum,
    Select,
    AdvancedDataGrid,
    SelectWidthEnum
} from "edah-component/dist"
import {t} from "i18next";
import useToast from "../../hooks/useToast"
import {SearchTextField} from "../../components/SearchTextField/SearchTextField"

const NotifyObjectTable = ({cvisNotifyObject, editableNotifyObject, onOptionChange}) => {
    const columns = [
        {field: 'dateTime', headerName: '通報日期時間', width: 180},
        {field: 'encounterId', headerName: '單號', width: 150},
        {field: 'user', headerName: '通報人員', width: 150},
        {
            field: 'notifyObject',
            headerName: '通報對象',
            width: 200,
            renderCell: (params) => {
                if (params.row.isEditable) {
                    return (
                        <Select
                            showLabel={false}
                            value={params.value}
                            notched={true}
                            displayEmpty={true}
                            data={{
                                label: '通報對象',
                                options: params.row.options.map((item) => ({
                                    label: item,
                                    value: item
                                })) || []
                            }}
                            onChange={(value) => onOptionChange(params.row.id, value)}
                            width={SelectWidthEnum.Medium}
                        />
                    );
                } else {
                    return `${params.row.cvisNotifyObjectName || ''}`;
                }
            },
        },
    ];

    const rows = useMemo(() => {
        const notifyObjectRows = Array.isArray(cvisNotifyObject) ? cvisNotifyObject.map((item, index) => ({
            id: index,
            dateTime: item.cvisRenotifyDatetime,
            encounterId: item.encounterId,
            user: item.cvisNotifyUserName,
            cvisNotifyObjectName: item.cvisNotifyObjectName,
            cvisObjectType: item.cvisObjectType,
            isEditable: false,
        })) : [];

        const editableRows = Array.isArray(editableNotifyObject) ? editableNotifyObject.map((item, index) => ({
            id: index,
            dateTime: item.dateTime,
            encounterId: item.encounterId,
            user: item.user,
            notifyObject: item.object,
            options: item.option,
            isEditable: true,
        })) : [];

        return [...notifyObjectRows, ...editableRows];
    }, [cvisNotifyObject, editableNotifyObject]);

    return (
        <AdvancedDataGrid
            rows={rows}
            columns={columns}
            hideHeader={false}
            checkboxSelection={false}
            disableRowSelectionOnClick={true}
            disableColumnMenu={true}
            getRowHeight={() => 56}
        />
    );
};


/**
 * A級危急值逾時回覆再通知
 * @return {JSX.Element}
 */
const CrisisAlertTimeoutReplyAssignmentAPopup = () => {
    const showToast = useToast()
    // 通報單位選單
    const [unit, setUnit] = useState([])
    //通報單位
    const [cvisNotifyUnit, setCvisNotifyUnit] = useState('')
    //A級危急值逾時回覆再通知清單
    const [isInitialized, setInitialized] = useState(false)
    // 使否已經初始化
    const [cvisRenotifyList, setCvisRenotifyList] = useState([])
    //過濾後的清單
    const [filteredList, setFilteredList] = useState([])
    //焦點索引值
    const [focusIndex, setFocusIndex] = useState(null)
    //進階搜尋字串
    const [queryString, setQueryString] = useState('')
    //通報對象資料
    const [cvisNotifyObject, setCvisNotifyObject] = useState({})
    //可選擇的通報對象
    const [notifyObjectOption, setNotifyObjectOption] = useState([
        "主治醫師",
        "院醫師",
        "護理站電腦",
        "職務代理人",
        "感染管制師"
    ])
    //待編輯通報對象
    const [editableNotifyObject, setEditalbeNotifyObject] = useState([])
    // pagination 控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,   //default
        pageSize: 10, //default
        totalItemSize: 0,
        totalPageSize: 0,
    })
    // 通報對象 pagination 控制變數
    const [notifyObjectPaginationProps, setNotifyObjectPaginationProps] = useState({
        pageNum: 1,   //default
        pageSize: 10, //default
        totalItemSize: 0,
        totalPageSize: 0,
    })

    /**
     * A級危急值逾時回覆再通知表格指定頁碼變動事件
     * @param page {Number}
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisClassANoReply(page, paginationProps.pageSize);
    }

    /**
     * A級危急值逾時回覆再通知表格指定每頁筆數變動事件
     * @param e {Event}
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        handleQueryCvisClassANoReply(paginationProps.pageNum, newSize);
    }

    /**
     * A級危急值逾時回覆再通知表格上一頁按鈕事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisClassANoReply(page, paginationProps.pageSize);
    }

    /**
     * A級危急值逾時回覆再通知表格下一頁按鈕事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryCvisClassANoReply(page, paginationProps.pageSize);
    }

    /**
     * 通報清單表格指定頁碼變動事件
     * @param page {number}
     * @return {void}
     */
    const onNotifyPaginationPageOnChange = (page) =>
        setNotifyObjectPaginationProps({
            ...notifyObjectPaginationProps,
            pageNum: page
        })

    /**
     * 通報清單表格指定每頁筆數變動事件
     * @param e {Event}
     * @return {void}
     */
    const onNotifyPaginationPageSizeOnChange = (e) =>
        setNotifyObjectPaginationProps({...notifyObjectPaginationProps, pageSize: parseInt(e.target.value)})

    /**
     * 通報清單表格上一頁按鈕事件
     * @return {void}
     */
    const onNotifyPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setNotifyObjectPaginationProps({
            ...notifyObjectPaginationProps,
            pageNum: page
        })
    }

    /**
     * 通報清單表格下一頁按鈕事件
     * @return {void}
     */
    const onNotifyPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setNotifyObjectPaginationProps({
            ...notifyObjectPaginationProps,
            pageNum: page
        });
    }

    /**
     * 控制通報單位選單變動事件
     * @param value {String}
     * @return {void}
     */
    const handleCvisNotifyUnitOnChange = (value) => {
        setCvisNotifyUnit(value)
        setFocusIndex(null)
    }

    /**
     * 新增內容
     * @return {void}
     */
    const handleAddRenotifyContent = () => {
        if (focusIndex === null) {
            showToast({message: "請選擇一筆資料！", type: AlertTypeEnum.Error})
        } else {
            if (!arrayIsEmpty(notifyObjectOption)) {
                setEditalbeNotifyObject([...editableNotifyObject, {
                    dateTime: getCurrentFormattedDateTime(),
                    cvisId: filteredList[focusIndex].cvisNotifyId,
                    encounterId: filteredList[focusIndex].encounterId,
                    user: localStorage.getItem("username"),
                    option: notifyObjectOption,
                    type: "vs", //不確定是資料帶入還是可選，先寫死
                    object: notifyObjectOption[0]
                }])
            }
        }
    }

    /**
     * 控制可編輯通報對象選項值變動事件
     * @param index {Number}
     * @param value {String}
     * @return {void}
     */
    const handleEditableObjectValueChange = (index, value) => {
        // 取得當前可編輯通報對象物件
        let newEditableNotifyObject = [...editableNotifyObject]
        // 根據索引值，更新對應通報對象值
        newEditableNotifyObject[index].object = value
        // 儲存更新後的通報對象
        setEditalbeNotifyObject(newEditableNotifyObject)
    }

    /**
     * 當列表被點擊時，返回索引的index，然後使用index呼叫取得通報對象清單API
     * @param index {Number} 列表項目索引
     * @return {void}
     */
    const handleOnItemFocus = (index) => {
        setFocusIndex(index)
    }

    /**
     * 取得模糊匹配後的資料陣列
     * @param searchText {String}
     * @return {Array<Object>}
     */
    const getFilterData = (searchText) => fuzzySearchObjects(cvisRenotifyList, searchText)

    /**
     * 控制進階搜尋輸入框變動事件，當輸入框事件觸發時，更新進階搜尋字串，並取得模糊匹配後的資料陣列，並重置焦點索引值
     * @param event {Event}
     * @return {void}
     */
    const handleOnQueryStringChange = (event) => {
        const {value} = event.target
        setQueryString(value)
        setFilteredList(getFilterData(value))
        setFocusIndex(null)
    }

    /**
     * 批次儲存A級危急值逾時回覆再通知
     * @return {void}
     */
    const handleContentSave = () => {
        //根據editableNotifyObject的內容，批次儲存
        const saveList = editableNotifyObject.map((item) => ({
            cvisNotifyId: item.cvisId,
            cvisObjectType: item.type,
            cvisRenotifyDatetime: item.dateTime,
            cvisNotifyUser: item.user,
            cvisNotifyObject: item.object
        }))
        // 如果沒有可儲存的資料，則顯示提示訊息
        if (arrayIsEmpty(saveList)) {
            showToast({message: "無可儲存的資料！", type: AlertTypeEnum.Success})
        } else { // 有可儲存的資料，則執行儲存API
            saveCvisRenotify(saveList).then((res) => {
                // 如果儲存成功，則顯示成功訊息
                if (res.err === ApiErrorStatusEnum.Success) {
                    showToast({message: "再通知批次儲存成功！", type: AlertTypeEnum.Success})
                } else { // 儲存失敗
                    showToast({message: "再通知批次儲存失敗！", type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 根據焦點索引取得再通知紀錄清單
     * @return {void}
     */
    const getQueryCvisRenotify = () => {
        // 如果焦點索引不等於當前索引，則重置通報對象
        setEditalbeNotifyObject([])
        if (!arrayIsEmpty(filteredList)) { // 如果過濾後的清單不為空，則取得通報對象
            queryCvisRenotify({
                "cvisNotifyId": filteredList[focusIndex]?.cvisNotifyId,
                "pageNum": notifyObjectPaginationProps.pageNum,
                "pageSize": notifyObjectPaginationProps.pageSize
            }).then((res) => {
                // 錯誤狀態 / 資料 / 訊息
                const {err, data, msg} = res
                // 如果取得通報對象成功
                if (err === ApiErrorStatusEnum.Success) {
                    // 資料 / 總筆數 / 總頁數
                    const {dataList, totalItemSize, totalPageSize} = data
                    // 取得通報對象
                    setCvisNotifyObject(dataList)
                    // 設置分頁
                    setNotifyObjectPaginationProps({
                        ...notifyObjectPaginationProps,
                        totalItemSize: totalItemSize,
                        totalPageSize: totalPageSize,
                    })
                } else { // 取得通報對象失敗
                    // 重置通報對象
                    setCvisNotifyObject([])
                    // 重置通報對象分頁控制變數
                    setNotifyObjectPaginationProps({
                        totalItemSize: 0,
                        totalPageSize: 0,
                        pageNum: 1,
                        pageSize: 10
                    })
                    showToast({message: msg, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 取得A級危急值逾時回覆再通知清單
     * @param page {Number}
     * @param pageSize {Number}
     * @return {void}
     */
    const handleQueryCvisClassANoReply = (page, pageSize) => {
        queryCvisClassANoReply({
            "cvisNotifyUnit": cvisNotifyUnit,
            "pageNum": page,
            "pageSize": pageSize
        }).then((res) => {
            // 錯誤狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 資料 / 總筆數 / 總頁數
                const {dataList, totalItemSize, totalPageSize} = data

                // 設定A級危急值逾時回覆再通知清單
                setCvisRenotifyList(dataList)
                // 設定過濾後的清單
                setFilteredList(dataList)
                // 設定分頁
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: totalItemSize,
                    totalPageSize: totalPageSize,
                    pageNum: page,
                    pageSize: pageSize
                })
                // 設定通報對象
                setCvisNotifyObject([])
                // 設定可編輯通報對象
                setEditalbeNotifyObject([])
            } else { // 取得A級危急值逾時回覆再通知清單失敗
                // 重置再通知清單
                setCvisRenotifyList([])
                // 重置過濾後的清單
                setFilteredList([])
                // 重置分頁控制變數
                setPaginationProps({
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageNum: 1,
                    pageSize: 10
                })
                // 設定通報對象
                setCvisNotifyObject([])
                // 設定可編輯通報對象
                setEditalbeNotifyObject([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 監聽初始化狀態，當初始化完成後，取得A級危急值逾時回覆再通知清單
     * @return {void}
     */
    useEffect(() => {
        // 初始化之後，才取得A級危急值逾時回覆再通知清單，避免出現初始化前跑出查無訊息
        if (isInitialized) {
            handleQueryCvisClassANoReply(paginationProps.pageNum, paginationProps.pageSize)
        }
    }, [isInitialized, cvisNotifyUnit])


    /**
     * 監聽notifyObjectPaginationProps，當通報對象分頁控制變數變動時，取得通報對象清單
     * @return {void}
     */
    useEffect(() => {
        // 只有焦點索引值存在時，才取得通報對象
        if (focusIndex) {
            getQueryCvisRenotify()
        }
    }, [notifyObjectPaginationProps.pageSize, notifyObjectPaginationProps.pageNum, focusIndex])

    /**
     * 初始化
     * @return {void}
     */
    useEffect(() => {
        queryCvisNotifyUnit({}).then((res) => {
            // 錯誤狀態 / 資料 / 訊息
            const {err, data, msg} = res

            if (err === ApiErrorStatusEnum.Success) {
                // 設定通報單位
                setUnit(data)
                // 取得通報單位後，設定通報單位
                if (data.length > 0) {
                    setCvisNotifyUnit(res.data[0].cvisNotifyUnitNo)
                }
                setInitialized(true)
            } else { // 取得通報單位失敗
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }, [])

    return (
        <div className="w-full">
            <div className='w-full flex flex-col gap-4'>
                <div className={`w-full bg-white rounded-[6px]`}>
                    {/*標題*/}
                    <div
                        className="title w-full flex flex-row items-center justify-between px-6 py-4 mb-4 border-b-[1px] border-[rgba(0,0,0,0.15)]">
                        <h3 className="text-[20px]">{'A級危急值逾時回覆再通知'}</h3>
                    </div>
                    {
                        <>
                            {/* 主要內容 */}
                            <div className='w-full p-4 flex flex-col gap-4 overflow-x-scroll'>
                                {/* 醫生及回覆資訊 */}
                                <div className='w-full flex flex-row justify-between items-center'>
                                    <div className='w-[310px] h-10 flex flex-row justify-start items-center text-left'>
                                        {/* <p>通報單位：</p> */}
                                        <Select
                                            data={{
                                                label: '通報單位',
                                                options: unit?.map((item) => ({
                                                    label: item.cvisNotifyUnitName,
                                                    value: item.cvisNotifyUnitNo
                                                }))
                                            }}
                                            value={cvisNotifyUnit}
                                            onChange={handleCvisNotifyUnitOnChange}
                                            notched={true}
                                            displayEmpty={true}
                                            width='Medium'/>
                                    </div>
                                </div>
                                {/* 資料內容 */}
                                <Card sx={{pt: 1}}>
                                    {/* 搜尋欄 */}
                                    <div className='flex justify-start mb-4'>
                                        <SearchTextField
                                            value={queryString}
                                            onChange={handleOnQueryStringChange}
                                            placeholder={t('general.advancedSearch')}/>
                                    </div>
                                    {/* 清單表格 */}
                                    <div className='min-w-[1664px]'>
                                        <ul className='tableList crisisAlertTimeoutReplyAssignmentATable max-h-[736px]'>
                                            <li className='title'>
                                                <div>發送日期時間</div>
                                                <div>病歷號</div>
                                                {/*姓名*/}
                                                <div>{t('general.username')}</div>
                                                <div>主治醫師</div>
                                                <div>醫師姓名</div>
                                                <div>護理站代號</div>
                                                <div>護理站GSM</div>
                                                <div>值班人員</div>
                                                <div>即時床號</div>
                                                <div>通報內容</div>
                                            </li>
                                            {
                                                !objectIsEmpty(filteredList) && (
                                                    filteredList.map((item, index) =>
                                                        <CvisListItem key={index} index={index} item={item} type="APopup1"
                                                                      handleItemOnClick={handleOnItemFocus}/>
                                                    )
                                                )
                                            }
                                        </ul>
                                    </div>
                                    {/* 分頁 */}
                                    <Pagination
                                        totalPageSize={paginationProps.totalPageSize}
                                        pageSize={paginationProps.pageSize}
                                        totalSize={paginationProps.totalItemSize}
                                        currentPage={paginationProps.pageNum}
                                        onPageOnChange={onPaginationPageOnChange}
                                        onPageSizeChange={onPaginationPageSizeOnChange}
                                        onPrevPageOnClick={onPaginationPreviousOnClick}
                                        onNextPageOnClick={onPaginationNextOnClick}
                                    />
                                    {/* 分頁 */}
                                    <div className="w-full h-[60px] py-[10px]">
                                        <Button sx={{display: 'flex', justifyContent: 'center'}}
                                                color={ButtonColorEnum.Success} variant={ButtonVariantEnum.Text}
                                                size={ButtonSizeEnum.Large} icon={IconEnum.Add}
                                                text='新增內容' onClick={handleAddRenotifyContent}/>
                                    </div>
                                    <div className='min-w-[1664px] text-left'>
                                        {
                                            <NotifyObjectTable
                                                cvisNotifyObject={cvisNotifyObject}
                                                editableNotifyObject={editableNotifyObject}
                                                onOptionChange={handleEditableObjectValueChange}
                                            />}
                                    </div>
                                    {/* 分頁 */}
                                    <Pagination
                                        totalPageSize={notifyObjectPaginationProps.totalPageSize}
                                        pageSize={notifyObjectPaginationProps.pageSize}
                                        totalSize={notifyObjectPaginationProps.totalItemSize}
                                        currentPage={notifyObjectPaginationProps.pageNum}
                                        onPageOnChange={onNotifyPaginationPageOnChange}
                                        onPageSizeChange={onNotifyPaginationPageSizeOnChange}
                                        onPrevPageOnClick={onNotifyPaginationPreviousOnClick}
                                        onNextPageOnClick={onNotifyPaginationNextOnClick}/>
                                </Card>
                            </div>
                            {/* 存檔按鈕 */}
                            <div className="w-full flex justify-end px-4 py-[10px] border-solid border-[1px]">
                                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                        size={ButtonSizeEnum.Medium} text={t('general.saveFile')}
                                        onClick={handleContentSave}/>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default CrisisAlertTimeoutReplyAssignmentAPopup
