import React, {useMemo, useState} from 'react'
import CustomTable from '../RSISCustomTable'
import ListItem from './ListItem'
import {usePopup} from '../PopupProvider'
import {ApiErrorStatusEnum, multiTermFuzzySearchObjects, stringIsEmpty, TimeslotEnum} from 'edah_utils/dist'
import DeletePopup from '../Popup/DeletePopup'
import SpecialPermissionMaintain from './SpecialPermissionMaintain'
import {t} from "i18next"
import {rsisDelSpecialRole, rsisQuerySpecialRole} from "../../../api/v1/RSIS"
import QueryPersonList from "../Modal/QueryPersonList";
import {queryAllDivision, queryAllDoctor} from "../../../api/v1/Menu"
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    DatePicker,
    IconEnum,
    Search,
    Select,
    SizeEnum,
    TextField,
    SelectWidthEnum
} from "edah-component/dist"
import dayjs from "dayjs";

// 表格欄位
const fields = [
    "",
    "科別",
    "醫師",
    "時段",
    "看診日期",
    "診室名稱",
]

/**
 * 報備支援-特殊權限設定頁面
 * @return {JSX.Element}
 */
const SpecialPermissionSetting = () => {
    // 科別代號搜尋文字
    const [divSearchText, setDivSearchText] = useState('')
    // 科別代號
    const [div, setDiv] = useState('')
    // 醫師名稱
    const [user, setUser] = useState('')
    // 時段
    const [apn, setApn] = useState('all')
    // 看診日期
    const [encountDate, setEncountDate] = useState('')
    // 診室號
    const [clinicNo, setClinicNo] = useState('')
    // 列表資料
    const [dataList, setDataList] = useState([])
    // 科別清單
    const [divisionList, setDivisionList] = useState([])
    // 醫師清單
    const [doctorList, setDoctorList] = useState([])
    // 人員清單查詢彈窗開關
    const [showQueryPersonList, setShowQueryPersonList] = useState(false)
    const List = ListItem

    // 分頁
    const paginationProps = useState({
        total: 100,
        pageSize: 10,
        current: 1,
        onChange: (page) => {
        }
    })

    // 使用上下文
    const {
        showDeletePopup,
        setShowDeletePopup,
        showSpecialPermissionMaintain,
        setShowSpecialPermissionMaintain,
        focusItem,
        setFocusItem,
        showToast,
    } = usePopup()

    /**
     * 列表刪除事件
     * @return {void}
     */
    const handleListItemOnDelete = () => delSpecialRole(focusItem)

    /**
     * 新增列表事件，根據editable狀態新增一個可編輯或不可編輯的列表
     * @return {void}
     */
    const handleAddItemOnClick = () => {
        setFocusItem(null)
        setShowSpecialPermissionMaintain(true)
    }

    /**
     * 科別代號更新時
     * @param _ {Event} 事件
     * @param option {Object} 科別代號
     * @return {void}
     */
    const handleDivChange = (_, option) => {
        const newValue = option?.divNo ? `${option?.divNo} ${option?.divName}` : ''
        setDiv(newValue)
    }

    /**
     * 科別名稱更新時
     * @return {void}
     */
    const handleUserChange = (value) => {
        // 設定醫師
        setUser(`${value.userNo} ${value.userName}`)
        // 關閉人員清單查詢
        setShowQueryPersonList(false)
    }

    /**
     * 查詢
     * @return {void}
     */
    const handleQuery = () => {
        // 取得特殊權限資料
        getQuerySpecialRole()
    }

    /**
     * 人員清單查詢表單關閉時
     * @return {void}
     */
    const handleQueryPersonListOnClose = () => setShowQueryPersonList(false)

    /**
     * 特殊權限維護表單關閉時
     * @return {void}
     */
    const handleSpecialPermissionMaintainOnClose = () => setShowSpecialPermissionMaintain(false)

    /**
     * 刪除彈窗關閉時
     * @return {void}
     */
    const handleDeletePopupOnClose = () => setShowDeletePopup(false)

    /**
     * 搜尋輸入框改變時
     * @param _ {Event} 事件
     * @param v {String} 值
     * @return {void}
     */
    const handleSearchOnInputChange = (_, v) => setDivSearchText(v)

    /**
     * 取得所有科別
     * @return {void}
     */
    const getAllDivision = () => {
        queryAllDivision({}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDivisionList(data)
            } else {
                setDivisionList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 查詢所有醫生
     * @return {void}
     */
    const getAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDoctorList(data)
            } else {
                setDoctorList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 新增或修改後更新列表
     * @return {void}
     */
    const handleOnUpdate = () => getQuerySpecialRole()

    /**
     * 重置搜尋條件按鈕點擊時
     * @return {void}
     */
    const handleResetSearchButtonOnClick = () => {
        // 清空科別代號搜尋文字
        setDivSearchText('')
        // 清空科別代號
        setDiv("")
        // 清空醫師代號
        setUser("")
        // 清空時段
        setApn("all")
        // 清空看診日期
        setEncountDate("")
        // 清空診室號
        setClinicNo("")
    }

    /**
     * 看診日期改變時
     * @param newValue {dayjs} 新的日期
     * @return {void}
     */
    const handleEncountDateOnChange = (newValue) => {
        setEncountDate(dayjs(newValue).format('YYYY-MM-DD'))
    }

    /**
     * 時段選擇變更時
     * @param value {String} 時段
     */
    const handleSlotSelectOnChange = (value) => setApn(value)

    /**
     * 取得特殊權限
     * @return {void}
     */
    const getQuerySpecialRole = () => {
        rsisQuerySpecialRole({
            //部門代號
            divNo: div.indexOf(' ') > 0 ? div.split(' ')[0] : div,
            //人員代號
            userNo: user.indexOf(' ') > 0 ? user.split(' ')[0] : user,
            // 時段
            apn: apn === 'all' ? '' : apn,
            // 看診日期
            encountDate: !stringIsEmpty(encountDate) ? `${encountDate} 00:00:00` : '',
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setDataList(data)
            } else { // 取得失敗
                //清空資料
                setDataList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除特殊權限設定(無需稽核報備設定)資料
     * @param {Object} item 刪除的資料
     * @return {void}
     */
    const delSpecialRole = (item) => {
        rsisDelSpecialRole({
            specialRoleId: item.specialRoleId,
        }).then(res => {
            // 狀態 / 訊息
            const {err, msg} = res
            // 刪除成功
            if (err === ApiErrorStatusEnum.Success) {
                // 重新取得資料
                getQuerySpecialRole()
                //清空資料
                setFocusItem(null)
                // 關閉刪除popup
                setShowDeletePopup(false)
                // 顯示成功訊息
                showToast({message: msg, type: AlertTypeEnum.Success})
            } else { // 刪除失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得預設科別代號
     * @return {Object|null}
     */
    const getDefaultDivNoValue = useMemo(() => {
        const data = divisionList?.find(option => div?.includes(option?.divNo || ''))
        return data && div ? data : null
    }, [div])

    /**
     * 第一次執行時
     * @return {void}
     */
    useMemo(() => {
        // 取得所有科別
        getAllDivision()
        // 取得所有醫師
        getAllDoctor()
        // 取得所有特殊權限
        getQuerySpecialRole()
    }, [])

    return (
        <>
            <div className="flex flex-col justify-start px-4 py-2 gap-4">
                <div className='flex flex-row items-center justify-start gap-4'>
                    {/* 科別代號 */}
                    <div className="flex flex-row items-center justify-start ">
                        <Search
                            inputValue={divSearchText}
                            onInputChange={handleSearchOnInputChange}
                            inputSize={SizeEnum.Medium}
                            freeSolo
                            value={getDefaultDivNoValue}
                            onChange={handleDivChange}
                            filterOptions={(options, {inputValue}) => {
                                if (!inputValue) {
                                    return getDefaultDivNoValue || options
                                }
                                return multiTermFuzzySearchObjects(options, inputValue.split(' '))
                            }}
                            options={divisionList}
                            getOptionLabel={(option) => `${option.divNo} ${option.divName}`}
                            inputLabel='科別代號'

                        />
                    </div>
                    {/* 醫師代號 */}
                    <div className="flex flex-row items-center justify-start">
                        <TextField
                            label="員工/醫師代碼"
                            value={user}
                            onClick={() => setShowQueryPersonList(true)}
                        />
                    </div>
                    {/* 時段 */}
                    <div className="flex flex-row items-center justify-start gap-2 text-left">
                        <Select
                            data={{
                                label: '時段',
                                options: [
                                    {value: TimeslotEnum.Morning, label: t('general.dateTime.timeslot.short.morning')},
                                    {
                                        value: TimeslotEnum.Afternoon,
                                        label: t('general.dateTime.timeslot.short.afternoon')
                                    },
                                    {value: TimeslotEnum.Night, label: t('general.dateTime.timeslot.short.night')},
                                    {value: "all", label: '全部'}
                                ]
                            }}
                            value={apn}
                            onChange={handleSlotSelectOnChange}
                            width={SelectWidthEnum.Small}
                            notched={true}
                            displayEmpty={true}
                        />
                    </div>
                    {/* 看診日期 */}
                    <div className="flex flex-row items-center justify-start gap-2">
                        <DatePicker
                            size={SizeEnum.Small}
                            label="看診日期"
                            value={encountDate ? dayjs(encountDate) : null}
                            onChange={handleEncountDateOnChange}
                        />
                    </div>
                    {/* 診室號 */}
                    <TextField
                        label="診室名稱"
                        value={clinicNo}
                        onChange={(e) => setClinicNo(e.target.value)}/>
                    <div className="source flex flex-row items-center justify-start">
                        {/* 查詢按鈕 */}
                        <Button text={t('general.query')} variant={ButtonVariantEnum.Contained}
                                size={ButtonSizeEnum.Medium} color={ButtonColorEnum.Primary}
                                onClick={handleQuery}/>
                        {/*清除查詢條件按鈕*/}
                        <Button sx={{
                            marginLeft: '1rem'
                        }} text={t('general.clearQueryParams')} variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium} color={ButtonColorEnum.Primary}
                                onClick={handleResetSearchButtonOnClick}/>
                    </div>
                </div>
                {/*新增按鈕*/}
                <Button sx={{
                    width: '76px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '4px',
                    marginLeft: '4px'
                }} text={t('general.add')} icon={IconEnum.Add} size={ButtonSizeEnum.Large}
                        variant={ButtonVariantEnum.Text} color={ButtonColorEnum.Success}
                        onClick={handleAddItemOnClick}/>
                {/* 表格 */}
                <CustomTable
                    advancedSearch={true}
                    fields={fields}
                    dataList={dataList}
                    ListItem={List}
                    hasSelectAll={false}
                    type="RSISSpecialSettingTable"
                    saveSlotBtn={false}
                    exportSlotBtn={false}
                    paginationProps={paginationProps}/>
            </div>
            {/* 刪除列表popup */}
            {
                showDeletePopup && (
                    <DeletePopup
                        handleDeletePopupClose={handleDeletePopupOnClose}
                        handleNotificationDelete={handleListItemOnDelete}
                        currentKey={focusItem}/>
                )
            }
            {/* 特殊權限維護 */}
            {
                showSpecialPermissionMaintain && (
                    <SpecialPermissionMaintain
                        doctorList={doctorList}
                        divList={divisionList}
                        item={focusItem}
                        handleOnClose={handleSpecialPermissionMaintainOnClose}
                        handleOnUpdate={handleOnUpdate}/>
                )
            }
            {/* 人員清單查詢 */}
            {
                showQueryPersonList && (
                    <QueryPersonList
                        handleOnClose={handleQueryPersonListOnClose}
                        handleOnUpdate={handleUserChange}/>
                )
            }
        </>
    )
}

export default SpecialPermissionSetting
