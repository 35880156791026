import React, {useEffect, useMemo, useState} from "react";
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, time2String,} from "edah_utils/dist";
import {regsQueryClinicOfTheWeek} from "../../../../api/v1/RegsMonth";
import WeeklyScheduleItem from "./WeeklyScheduleItem";
import {t} from "i18next";
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    DatePicker,
    Dialog,
    DialogVariant,
    Search,
    Select,
} from "edah-component/dist";
import dayjs, {Dayjs} from "dayjs";

/**
 * 當週班表
 * @param show {boolean} 是否顯示
 * @param data {Object} 資料
 * @param closePopupButtonOnClick {function} 關閉按鈕點擊事件
 * @param onSelectClinic {function} 選擇診所事件
 * @param fowardRegFlag 是否為補輸
 * @return {JSX.Element}
 */
const WeeklySchedule = ({
                            show = false,
                            data,
                            closePopupButtonOnClick,
                            onSelectClinic,
                            fowardRegFlag,
                        }) => {
    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show);
    //查詢條件
    const [queryInput, setQueryInput] = useState({
        encounterDate: "",
        divNo: "",
        doctorNo: "",
    });
    // 分院清單
    const [zonesetList, setZonesetList] = useState(
        data && data.zonesetList ? data.zonesetList : []
    );
    // 選定的分院
    const [selectedZone, setSelectedZone] = useState("");
    // 科別清單
    const [divList, setDivList] = useState([]);
    // 醫生清單
    const [doctorList, setDoctorList] = useState([]);
    // 診所清單
    const [clinicList, setClinicList] = useState([]);
    // 當週班表
    const [queryData, setQueryData] = useState([]);
    const [weekDates, setWeekDates] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState(null);

    const firstDateOfWeek = (dateString) => {
        const date = new Date(dateString);
        const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startDate = new Date(date);
        startDate.setDate(date.getDate() - dayOfWeek + 1); // Start from Monday

        return startDate;
    };

    /**
     * 分院選擇事件
     * @param value {string}
     */
    const handleZoneSelectOnChange = (value) => {
        setSelectedZone(value);
    };

    /**
     * 日期變更事件
     * @param date {Dayjs | null} 選定的日期
     */
    const handleDateOnChange = (date) => {
        if (!date || !date.isValid()) {
            return;
        }

        const formattedDate = date.format("YYYY-MM-DD");
        const year = date.year();

        if (year > 9999) {
            return;
        }

        // 找出選定日期當週的第一天
        if (
            firstDateOfWeek(formattedDate).getTime() !==
            firstDateOfWeek(queryInput.encounterDate).getTime()
        ) {
            // 重新搜尋醫師列表和科別列表
            getBaseDoctorAndDivision(formattedDate);
        }

        const newQueryInput = {
            encounterDate: formattedDate,
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
        };

        // 更新 queryInput 狀態
        setQueryInput(newQueryInput);

        // 根據新輸入資料搜尋
        queryQueryClinicOfTheWeek(newQueryInput);
    };

    /**
     * 查詢當週班表
     * @param searchInput {Object} 查詢條件
     */
    const queryQueryClinicOfTheWeek = (searchInput) => {
        //切割字串
        const date = time2String(
            searchInput.encounterDate,
            "YYYY-MM-DD 00:00:00"
        );

        regsQueryClinicOfTheWeek({
            encounterDate: date,
            doctorNo: searchInput.doctorNo
                ? searchInput.doctorNo.split(" ")[0]
                : null,
            divNo: searchInput.divNo ? searchInput.divNo.split(" ")[0] : null,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setQueryData(res.data);
            }
        });
    };

    /**
     * 取得日期當週的醫師 科別基本資料
     * @param date {String} 日期
     */
    const getBaseDoctorAndDivision = (date) => {
        regsQueryClinicOfTheWeek({
            encounterDate: time2String(date, "YYYY-MM-DD 00:00:00"),
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setDivList(res.data.divList);
                setDoctorList(res.data.doctorList);

                const allClinic = [
                    ...res.data.monList,
                    ...res.data.tueList,
                    ...res.data.wedList,
                    ...res.data.thuList,
                    ...res.data.friList,
                    ...res.data.satList,
                    ...res.data.sunList,
                ];
                setClinicList(allClinic);
            }
        });
    };

    /**
     * 診間點選事件
     */
    const handleClinicOnClick = (disabled, clinicData) => {
        if (disabled) return;
        setSelectedClinic(clinicData);
    };

    /**
     * 確定按鈕點擊事件
     */
    const handleConfirmOnClick = () => {
        if (!objectIsEmpty(selectedClinic)) {
            onSelectClinic(selectedClinic.encounterDate, selectedClinic);
        }
        closePopupButtonOnClick();
    };

    /**
     * 處理科別搜尋文字框變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleDepartmentSearchTextOnChange = (value) => {
        // 設定科別選項
        setQueryInput({
            ...queryInput,
            divNo: value,
        });
    };

    /**
     * 處理科別選項點擊事件
     * @param option {Object} 科別
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (option) => {
        // 科別不為空
        if (option !== null) {
            // 科別編號
            const no = option.divNo;
            // 科別名稱
            const name = option.divName;
            // 設定科別選項
            setQueryInput({
                ...queryInput,
                divNo: `${no} ${name}`,
            });
        }
    };

    /**
     * 處理醫師搜尋文字框變更事件
     * @param value {String}
     * @return {void}
     */
    const handleDoctorSearchTextOnChange = (value) => {
        // 設定醫師選項
        setQueryInput({
            ...queryInput,
            doctorNo: value,
        });
    };

    /**
     * 處理醫師選項點擊事件
     * @param option {Object} 醫師
     * @return {void}
     */
    const handleDoctorOptionOnClick = (option) => {
        // 醫師不為空
        if (option !== null) {
            // 醫師編號
            const no = option.doctorNo;
            // 醫師名稱
            const name = option.doctorName;
            // 設定醫師選項
            setQueryInput({
                ...queryInput,
                doctorNo: `${no} ${name}`,
            });
        }
    };

    /**
     * 查詢按鈕點擊事件
     */
    const handleQueryOnClick = () => {
        queryQueryClinicOfTheWeek(queryInput);
    };

    /**
     * 點擊前一週
     * @return {void}
     */
    const handlePreviousWeekOnClick = () => {
        const date = new Date(queryInput.encounterDate);
        const prevWeekDate = new Date(date);
        prevWeekDate.setDate(date.getDate() - 7);

        //重新搜尋doctor list div list
        getBaseDoctorAndDivision(prevWeekDate);

        const newQueryInput = {
            encounterDate: time2String(prevWeekDate, "YYYY-MM-DD"),
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
        };

        setQueryInput(newQueryInput);

        //搜尋
        queryQueryClinicOfTheWeek(newQueryInput);
    };

    /**
     * 點擊下一週
     * @return {void}
     */
    const handleNextWeekOnClick = () => {
        const date = new Date(queryInput.encounterDate);
        const nextWeekDate = new Date(date);
        nextWeekDate.setDate(date.getDate() + 7);

        //重新搜尋doctor list div list
        getBaseDoctorAndDivision(nextWeekDate);

        const newQueryInput = {
            encounterDate: time2String(nextWeekDate, "YYYY-MM-DD"),
            doctorNo: queryInput.doctorNo,
            divNo: queryInput.divNo,
        };

        setQueryInput(newQueryInput);

        //搜尋
        queryQueryClinicOfTheWeek(newQueryInput);
    };

    /**
     * 渲染時段 日診間表
     * @param apn {Number} 時段
     * @param clinicArray {Array} 診間Array
     * @returns {Element}
     */
    const renderClinicOfDayListByApn = (apn, clinicArray) => {
        // 過濾出當前時段的班表
        const filteredClinics = arrayIsEmpty(clinicArray)
            ? []
            : clinicArray.filter((clinic) => clinic.apn === Number(apn));

        // 判斷是否選中
        const isSelected = (data) => (
            !objectIsEmpty(data) && !objectIsEmpty(selectedClinic) &&
            ['divNo', 'doctorNo', 'clinicNo', 'apn', 'encounterDate']
                .every((key) => data[key] === selectedClinic[key])
        );

        // 判斷是否為可掛號時段
        const isRegistrationPeriod = (clinic) => {
            const currentDate = new Date();
            const targetDate = new Date(clinic.encounterDate);
            const tmpCurrentDate = new Date(currentDate).setHours(0, 0, 0, 0);

            if (fowardRegFlag) return true; // 補輸情況
            if (targetDate < tmpCurrentDate || clinic.stopClinicFlag) return false; // 停診或已過的時段

            const currentHour = currentDate.getHours();
            const sameDay = time2String(targetDate, "YYYYMMDD") === time2String(currentDate, "YYYYMMDD");
            //當日時段判斷
            if (sameDay) {
                if (currentHour >= 21) return false; // 晚診後就不開放當日掛號
                if (clinic.apn === 1 && currentHour >= 12) return false;
                if (clinic.apn === 2 && currentHour >= 17) return false;
            }
            return true;
        };

        // 背景顏色判斷
        const clinicItemBkColor = (disabled, clinic) =>
            disabled ? "bg-[#d4d4d8]" : isSelected(clinic) ? "bg-[#bee3f8]" : "bg-[#fff]";

        return (
            <ul className="border rounded-md min-w-[235px] w-[235px] max-w-[235px] h-full overflow-y-auto bg-[#fff]">
                {arrayIsEmpty(filteredClinics) ? (
                    <p className="flex items-center justify-center text-xl font-normal text-[#a1a1aa] h-full">
                        沒有醫生看診 <br/> 請選其他時間
                    </p>
                ) : (
                    filteredClinics.map((clinic, index) => {
                        const disabled = !isRegistrationPeriod(clinic);
                        const isFull = clinic.regFullFlag === 1;

                        return (
                            <WeeklyScheduleItem
                                key={index}
                                record={clinic}
                                disabled={disabled}
                                isFull={isFull}
                                bgColor={clinicItemBkColor(disabled, clinic)}
                                onClickItem={handleClinicOnClick}
                            />
                        );
                    })
                )}
            </ul>
        );
    };

    /**
     * 取得預設科別值
     */
    const getDefaultDivValue = useMemo(() => {
        const data = divList?.find(option => queryInput?.divNo?.includes(option?.divNo || ''))
        return data ? data : null
    }, [divList, queryInput?.divNo])

    /**
     * 取得預設醫師值
     */
    const getDefaultDoctorNoValue = useMemo(() => {
            const data = doctorList?.find(option => queryInput?.doctorNo?.includes(option?.doctorNo || ''))
            return data ? data : null
        }, [doctorList, queryInput?.doctorNo]
    )
    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        if (show) {
            const firstDate = time2String(
                data != null && data.date ? data.date : new Date(),
                "YYYY-MM-DD"
            );
            const firstDoctorNo =
                objectIsEmpty(data) || objectIsEmpty(data.doctorNo)
                    ? ""
                    : data.doctorNo;
            const firstDivNo =
                objectIsEmpty(data) || objectIsEmpty(data.divNo)
                    ? ""
                    : data.divNo;
            const newQueryInput = {
                encounterDate: firstDate,
                //醫生
                doctorNo: firstDoctorNo,
                //科別
                divNo: firstDivNo,
            };

            setQueryInput(newQueryInput);

            setZonesetList(data && data.zonesetList ? data.zonesetList : []);
            //取得醫師 科別基本資料
            getBaseDoctorAndDivision(firstDate);
            //查詢日班表資料
            queryQueryClinicOfTheWeek(newQueryInput);
        } else {
            setQueryData([]);
        }

        setShowPopup(show);
    }, [show]);

    /**
     * 監聽日期變更
     */
    useEffect(() => {
        const calculateWeekDates = (dateString) => {
            const date = new Date(dateString);
            const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
            const startDate = new Date(date);
            // 設定當前日期為星期日的情況，使其成為星期天 (0) 以符合 ISO 8601，其中星期一是1
            startDate.setDate(date.getDate() + (dayOfWeek === 0 ? -6 : 1 - dayOfWeek)); // Start from Monday

            const dates = [];
            for (let i = 0; i < 7; i++) {
                const current = new Date(startDate);
                current.setDate(startDate.getDate() + i);
                dates.push(time2String(current, "MM/DD")); // Convert to YYYY-MM-DD format
            }

            return dates;
        };

        setWeekDates(calculateWeekDates(queryInput.encounterDate));
    }, [queryInput.encounterDate]);

    /**
     * 監聽院區代碼輸入框變化
     */
    useEffect(() => {
        if (
            zonesetList.length > 0 &&
            !zonesetList.some((zone) => zone.zone === selectedZone)
        ) {
            setSelectedZone(zonesetList[0].zone);
        }
    }, [zonesetList]);

    return (
        <Dialog
            open={showPopup}
            title={"當週班表_選擇掛號"}
            content={
                <div>
                    {/*filter篩選*/}
                    <div className="filter flex flex-row items-center justify-between px-6 ">
                        <div className="flex flex-row space-x-2">
                            {/*院區*/}
                            <div>
                                <Select
                                    data={{
                                        label: "院區",
                                        options: zonesetList?.map(
                                            (zone) =>
                                                ({
                                                    value: zone.zone,
                                                    label: zone.zoneName,
                                                } || [])
                                        ),
                                    }}
                                    value={selectedZone}
                                    onChange={handleZoneSelectOnChange}
                                    width="Medium"
                                    notched={true}
                                />
                            </div>
                            <div className="flex flex-row space-x-5 items-center">
                                {/*科別*/}
                                {
                                    !arrayIsEmpty(divList) &&
                                    <Search
                                        freeSolo
                                        value={getDefaultDivValue}
                                        inputLabel={t('general.department')}
                                        onChange={(_e, value) => handleDepartmentOptionOnClick(value)}
                                        onInputChange={(_e, value) => handleDepartmentSearchTextOnChange(value)}
                                        disablePortal={false}
                                        options={divList}
                                        getOptionLabel={(option) => `${option.divNo} ${option.divName}`}/>
                                }
                                {/*醫師*/}
                                {
                                    !arrayIsEmpty(doctorList) &&
                                    <Search
                                        freeSolo
                                        value={getDefaultDoctorNoValue}
                                        inputLabel={t('general.doctor')}
                                        onChange={(_e, value) => handleDoctorOptionOnClick(value)}
                                        onInputChange={(_e, value) => handleDoctorSearchTextOnChange(value)}
                                        disablePortal={false}
                                        options={doctorList}
                                        getOptionLabel={(option) => `${option?.doctorNo} ${option?.doctorName}`}/>
                                }
                            </div>
                            <div className="flex flex-row space-x-2 items-center relative w-2/3">
                                <Button color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                        size={ButtonSizeEnum.Medium}
                                        onClick={handleQueryOnClick}>
                                    {t('general.query')}
                                </Button>
                            </div>

                        </div>

                        <DatePicker
                            size="small"
                            value={dayjs(queryInput.encounterDate)}
                            onChange={handleDateOnChange}
                        />
                    </div>

                    {/*週班Content*/}
                    <div
                        className="flex flex-col overflow-x-auto px-[16px] overflow-y-auto max-h-[calc(80vh-50px)] pt-1">
                        {/** 區塊 - 日期 */}
                        <div className="w-full py-1 pl-[72px] pr-[16px] mb-1">
                            <div className="w-full min-w-[1457px] flex flex-row gap-2.5">
                                <div className="w-full min-w-[233px] flex justify-center relative">
                                    <p>{weekDates[0]}(一)</p>
                                    <span className="absolute -left-9 -top-2 mr-2">
                                        <Button
                                            color={ColorEnum.Secondary}
                                            variant={ButtonVariantEnum.Outlined}
                                            size={ButtonSizeEnum.Medium}
                                            onClick={handlePreviousWeekOnClick}>
                                            {"< 前一週"}
                                        </Button>
                                    </span>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center">
                                    <p>{weekDates[1]}(二)</p>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center">
                                    <p>{weekDates[2]}(三)</p>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center">
                                    <p>{weekDates[3]}(四)</p>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center">
                                    <p>{weekDates[4]}(五)</p>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center">
                                    <p>{weekDates[5]}(六)</p>
                                </div>
                                <div className="w-full min-w-[233px] flex justify-center relative">
                                    <p className="absolute left-[calc(50%-7px)] top-1/2 -translate-y-1/2 -translate-x-1/2">
                                        {weekDates[6]}(日)
                                    </p>
                                    <span className="absolute -right-4 -top-2 mr-2">
                                        <Button
                                            color={ColorEnum.Secondary}
                                            variant={ButtonVariantEnum.Outlined}
                                            size={ButtonSizeEnum.Medium}
                                            onClick={handleNextWeekOnClick}>
                                            {"下一週 >"}
                                        </Button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/** 區塊 - 早 */}
                        <div
                            className="w-full min-w-[1688px] h-[250px] min-h-[250px] bg-[#EBF8FF] flex flex-row gap-2 px-4 py-1">
                            <div className="w-12 flex flex-row justify-center items-center px-1">
                                <div className="w-10 text-base">
                                    <p className="w-12 text-center">早</p>
                                    <p className="w-12 text-center">08:30</p>
                                    <p className="w-12 text-center">~</p>
                                    <p className="w-12 text-center">12:00</p>
                                </div>
                            </div>

                            {renderClinicOfDayListByApn(
                                1,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.monList)
                                    ? null
                                    : queryData.monList
                            )}
                            {renderClinicOfDayListByApn(
                                1,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.tueList)
                                    ? null
                                    : queryData.tueList
                            )}
                            {renderClinicOfDayListByApn(
                                1,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.wedList)
                                    ? null
                                    : queryData.wedList
                            )}
                            {renderClinicOfDayListByApn(
                                1,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.thuList)
                                    ? null
                                    : queryData.thuList
                            )}
                            {renderClinicOfDayListByApn(
                                1,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.friList)
                                    ? null
                                    : queryData.friList
                            )}
                            {renderClinicOfDayListByApn(
                                1,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.satList)
                                    ? null
                                    : queryData.satList
                            )}
                            {renderClinicOfDayListByApn(
                                1,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.sunList)
                                    ? null
                                    : queryData.sunList
                            )}

                            <div className="h-full flex items-center">
                                <div
                                    className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg"></div>
                            </div>
                        </div>

                        {/** 區塊 - 午 */}
                        <div
                            className="w-full min-w-[1688px] h-[250px] min-h-[250px] bg-customYellow flex flex-row gap-2 px-4 py-1">
                            <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                                <div className="w-10 h-24 text-base">
                                    <p className="w-12 text-center">午</p>
                                    <p className="w-12 text-center">13:30</p>
                                    <p className="w-12 text-center">~</p>
                                    <p className="w-12 text-center">17:00</p>
                                </div>
                            </div>
                            {renderClinicOfDayListByApn(
                                2,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.monList)
                                    ? null
                                    : queryData.monList
                            )}
                            {renderClinicOfDayListByApn(
                                2,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.tueList)
                                    ? null
                                    : queryData.tueList
                            )}
                            {renderClinicOfDayListByApn(
                                2,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.wedList)
                                    ? null
                                    : queryData.wedList
                            )}
                            {renderClinicOfDayListByApn(
                                2,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.thuList)
                                    ? null
                                    : queryData.thuList
                            )}
                            {renderClinicOfDayListByApn(
                                2,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.friList)
                                    ? null
                                    : queryData.friList
                            )}
                            {renderClinicOfDayListByApn(
                                2,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.satList)
                                    ? null
                                    : queryData.satList
                            )}
                            {renderClinicOfDayListByApn(
                                2,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.sunList)
                                    ? null
                                    : queryData.sunList
                            )}

                            <div className="h-full flex items-center">
                                <div
                                    className="flex items-center h-[40px] border-solid border-2 border-gray-400 rounded-lg"></div>
                            </div>
                        </div>

                        {/** 區塊 - 晚 */}
                        <div
                            className="w-full min-w-[1688px] h-[250px] min-h-[250px] bg-[#FBF5FF] flex flex-row gap-2 px-4 py-1">
                            <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                                <div className="w-10 h-24 text-base">
                                    <p className="w-12 text-center">晚</p>
                                    <p className="w-12 text-center">18:00</p>
                                    <p className="w-12 text-center">~</p>
                                    <p className="w-12 text-center">21:00</p>
                                </div>
                            </div>
                            {renderClinicOfDayListByApn(
                                3,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.monList)
                                    ? null
                                    : queryData.monList
                            )}
                            {renderClinicOfDayListByApn(
                                3,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.tueList)
                                    ? null
                                    : queryData.tueList
                            )}
                            {renderClinicOfDayListByApn(
                                3,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.wedList)
                                    ? null
                                    : queryData.wedList
                            )}
                            {renderClinicOfDayListByApn(
                                3,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.thuList)
                                    ? null
                                    : queryData.thuList
                            )}
                            {renderClinicOfDayListByApn(
                                3,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.friList)
                                    ? null
                                    : queryData.friList
                            )}
                            {renderClinicOfDayListByApn(
                                3,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.satList)
                                    ? null
                                    : queryData.satList
                            )}
                            {renderClinicOfDayListByApn(
                                3,
                                objectIsEmpty(queryData) ||
                                arrayIsEmpty(queryData.sunList)
                                    ? null
                                    : queryData.sunList
                            )}
                        </div>
                    </div>
                </div>}
            variant={DialogVariant.CONFIRM}
            paperStyleProps={{width: '100vw'}}
            onClose={closePopupButtonOnClick}
            onConfirm={handleConfirmOnClick}
            muiDialogContentProps={{sx: {overflowY: 'hidden'}}}/>
    )
};

export default WeeklySchedule;
